<template>
  <main v-if="check_auth">
    <div class=" rounded-lg p-5">
      <button
        class="bg-gray-800 py-2 px-3 rounded-md text-xs text-white"
        @click="$router.go(-1)"
      >
        &laquo; Back
      </button>
      <div class="flex justify-between items-center mt-10">
        <h1>Make Transfer</h1>
      </div>
      <div class="grid  grid-cols-3  gap-10 mt-5 w-full">
        <div class="w-full">
          <select
            class="border-2 border-gray-500 px-3 h-12 w-full"
            v-model="enquiry.bank"
          >
            <option disabled :value="null">
              Destination Bank
            </option>
            <option
              v-for="bank in banks"
              :value="bank.bank_code"
              :key="bank.bank_code"
            >
              {{ bank.name }}
            </option>
          </select>
        </div>
        <div class="w-full">
          <input
            v-model="enquiry.account"
            class="border-2 border-gray-500 px-3 w-full h-12"
            type="number"
            placeholder="Destination Account Number"
          />
        </div>
        <button
          :disabled="disabled"
          :class="disabled ? 'bg-gray-500 cursor-not-allowed' : 'bg-blue-900'"
          class="inline-block drop-shadow-2xl 
            text-white h-12 px-3  rounded-md mr-2 ml-5 leading-8"
          @click="nameEnquiry"
        >
          Search
        </button>
      </div>
      <p
        class=" text-red-500 text-sm mt-2"
        v-if="
          Object.keys(nameStatus).length && nameStatus.ResponseCode !== '00'
        "
      >
        {{ nameStatus.Message }}
      </p>
      <div
        v-if="
          Object.keys(nameStatus).length && nameStatus.ResponseCode === '00'
        "
        class="grid  grid-cols-3  gap-10 mt-5 w-full"
      >
        <div class="w-full">
          <input
            v-model="nameStatus.AccountName"
            class="border-2 border-gray-500 px-3 w-full h-12"
            type="text"
            placeholder="Destination Account Number"
            disabled
          />
        </div>
        <div class="w-full">
          <input
            v-model="formattedNumericValue"
            class="border-2 border-gray-500 px-3 w-full h-12"
            type="number"
            placeholder="Amount to transfer"
            min="0"
          />
        </div>
        <button
          @click="confirmation = true"
          :disabled="!amount || amount === 0"
          :class="
            !amount || amount === 0
              ? 'bg-gray-500 cursor-not-allowed'
              : 'bg-blue-900'
          "
          class="inline-block drop-shadow-2xl 
            text-white h-12 px-3  rounded-md mr-2 ml-5 leading-8"
        >
          Proceed
        </button>
      </div>
    </div>
    <Modal v-if="confirmation" :cancel="false">
      <div class=" text-center">
        Are you sure you want to transfer
        <div>{{ format(amount) }}?</div>
      </div>
      <div class="flex  gap-5 py-5 justify-center  items-center">
        <button
          @click="confirmation = false"
          type="submit"
          class="block uppercase shadow bg-red-600 mt-5 leading-6 hover:bg-red-700 focus:shadow-outline focus:outline-none text-white text-xs py-1 w-40 rounded"
        >
          Cancel
        </button>
        <button
          :class="isDisabled ? 'bg-gray-500 cursor-not-allowed' : ''"
          :disabled="isDisabled"
          @click="createBulkSweep"
          class="block uppercase shadow bg-blue-900 mt-5 leading-6  focus:shadow-outline focus:outline-none text-white text-xs py-1 w-40 rounded"
        >
          Submit
        </button>
      </div>
    </Modal>
  </main>
  <main v-else class="w-1/4 mx-auto">
    <h5 class="text-3xl font-bold text-blue-800 text-center">
      Sorry, you're not authorized to view this page.
    </h5>
  </main>
</template>

<script>
import { GET_USER_BUSINESS_ID } from "@/browser-db-config/localStorage";
import Modal from "../components/Modal.vue";
import kudaUsers from "../config/kudaUsers";
import { GET_USER_BUSINESS_DATA } from "../browser-db-config/localStorage";

export default {
  data() {
    return {
      enquiry: {
        bank: null,
        account: null,
      },
      nameStatus: {},
      amount: null,
      banks: [],
      isLoading: false,
      confirmation: false,
      isDisabled: false,
    };
  },
  methods: {
    format(balance) {
      const formatter = new Intl.NumberFormat("en-NG", {
        style: "currency",
        currency: "NGN",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
      if (balance) {
        return formatter.format(balance);
      }
    },
    async getBanks() {
      this.isLoading = true;
      try {
        const banks = await this.$store.dispatch("GET_BANKS");
        this.banks = banks.data.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
    updateNumericValue(e) {
      this.numericValue = e.target.value.replace(/[^0-9]/g, "");
    },
    async nameEnquiry() {
      this.isLoading = true;

      let payload = this.enquiry;
      try {
        const nameEnquiry = await this.$store.dispatch("NAME_ENQUIRY", payload);
        this.nameStatus = nameEnquiry.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
    async createBulkSweep() {
      this.isDisabled = true;
      let payload = {
        businessId: GET_USER_BUSINESS_ID(),
        provider: "kuda",
        destinationAccount: this.nameStatus.AccountNumber,
        destinationAccountName: this.nameStatus.AccountName,
        destinationBank: this.nameStatus.BankCode,
        amount: +this.amount,
        narration: "bulk sweep request",
      };
      try {
        const createBulkSweep = await this.$store.dispatch(
          "CREATE_BULK_SWEEP",
          payload
        );
        alert("Bulk Sweep Request Created Successfully");
        this.$router.go(-1);
      } catch (error) {
        console.log(error);
      } finally {
        this.isDisabled = false;
      }
    },
  },
  computed: {
    disabled() {
      return (
        !this.enquiry.bank ||
        !this.enquiry.account ||
        this.enquiry.account.length !== 10 ||
        this.isLoading
      );
    },
    formattedNumericValue: {
      get() {
        return this.amount;
      },
      set(newValue) {
        this.amount = newValue < 0 ? 0 : newValue;
      },
    },
    check_auth() {
      const userEmail = GET_USER_BUSINESS_DATA().email;
      return kudaUsers.includes(userEmail);
    },
  },
  async created() {
    await this.getBanks();
  },

  components: {
    Modal,
  },
};
</script>

<style>
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
